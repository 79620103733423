var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getSearchList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "edit", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.useFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "useFlag",
                    label: "사용여부",
                  },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c(
              "c-tree-table",
              {
                ref: "processTable",
                attrs: {
                  title: "공정 목록",
                  parentProperty: "upProcessCd",
                  customID: "processCd",
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  columnSetting: false,
                  isFullScreen: false,
                  usePaging: false,
                  expandAll: true,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "검색", icon: "search" },
                          on: { btnClicked: _vm.getSearchList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: _vm.title },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "신규 공정", icon: "add" },
                                  on: { btnClicked: _vm.addParent },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            this.data.processLevelCd == "10" &&
                            _vm.selectedProcessCd
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "신규 단위공정",
                                    icon: "add",
                                  },
                                  on: { btnClicked: _vm.addChild },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            _vm.dataeditable &&
                            _vm.selectedProcessCd
                              ? _c("c-btn", {
                                  attrs: { label: "삭제", icon: "remove" },
                                  on: { btnClicked: _vm.removeProcess },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.dataeditable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.saveType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.data.processLevelCd != "20"
                      ? _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  required: true,
                                  label: "공정명",
                                  name: "processName",
                                },
                                model: {
                                  value: _vm.data.processName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "processName", $$v)
                                  },
                                  expression: "data.processName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  required: true,
                                  label: "공정관리번호",
                                  name: "processNo",
                                },
                                model: {
                                  value: _vm.data.processNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "processNo", $$v)
                                  },
                                  expression: "data.processNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("c-plant", {
                                attrs: {
                                  required: true,
                                  editable: _vm.editable && _vm.dataeditable,
                                  label: "사업장",
                                  name: "plantCd",
                                },
                                model: {
                                  value: _vm.data.plantCd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "plantCd", $$v)
                                  },
                                  expression: "data.plantCd",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  required: true,
                                  label: "순번",
                                  name: "orderNo",
                                },
                                model: {
                                  value: _vm.data.orderNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "orderNo", $$v)
                                  },
                                  expression: "data.orderNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("c-checkbox", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  isFlag: true,
                                  label: "PSM대상",
                                  name: "psmFlag",
                                },
                                model: {
                                  value: _vm.data.psmFlag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "psmFlag", $$v)
                                  },
                                  expression: "data.psmFlag",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("c-checkbox", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  isFlag: true,
                                  label: "사용여부",
                                  name: "useFlag",
                                },
                                model: {
                                  value: _vm.data.useFlag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "useFlag", $$v)
                                  },
                                  expression: "data.useFlag",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-5" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  label: "주재료",
                                  name: "mainMaterial",
                                },
                                model: {
                                  value: _vm.data.mainMaterial,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "mainMaterial", $$v)
                                  },
                                  expression: "data.mainMaterial",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-5" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  label: "생산품",
                                  name: "productName",
                                },
                                model: {
                                  value: _vm.data.productName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "productName", $$v)
                                  },
                                  expression: "data.productName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-2" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  label: "근로자수",
                                  name: "workerCnt",
                                  suffix: _vm.$language("명"),
                                  type: "number",
                                },
                                model: {
                                  value: _vm.data.workerCnt,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "workerCnt", $$v)
                                  },
                                  expression: "data.workerCnt",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-textarea", {
                                attrs: {
                                  counter: true,
                                  editable: _vm.editable && _vm.dataeditable,
                                  label: "공정설명",
                                  name: "processDesc",
                                  rows: 2,
                                },
                                model: {
                                  value: _vm.data.processDesc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "processDesc", $$v)
                                  },
                                  expression: "data.processDesc",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-upload", {
                                attrs: {
                                  attachInfo: _vm.attachInfoL1Photo,
                                  editable: _vm.editable && _vm.dataeditable,
                                  label: "공정 사진",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-upload", {
                                attrs: {
                                  attachInfo: _vm.attachInfoL1Hazard,
                                  editable: _vm.editable && _vm.dataeditable,
                                  label: "그 밖의 유해위험정보",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.data.processLevelCd == "20"
                      ? _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  required: true,
                                  label: "단위공정명",
                                  name: "processName",
                                },
                                model: {
                                  value: _vm.data.processName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "processName", $$v)
                                  },
                                  expression: "data.processName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  required: true,
                                  label: "공정관리번호",
                                  name: "processNo",
                                },
                                model: {
                                  value: _vm.data.processNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "processNo", $$v)
                                  },
                                  expression: "data.processNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  required: true,
                                  label: "순번",
                                  name: "orderNo",
                                },
                                model: {
                                  value: _vm.data.orderNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "orderNo", $$v)
                                  },
                                  expression: "data.orderNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: false,
                                  label: "상위공정",
                                  name: "upProcessName",
                                },
                                model: {
                                  value: _vm.data.upProcessName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "upProcessName", $$v)
                                  },
                                  expression: "data.upProcessName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("c-dept-multi", {
                                attrs: {
                                  label: "관리부서",
                                  editable: _vm.editable && _vm.dataeditable,
                                  parentCheckDepts: _vm.data.managementDepts,
                                  plantCd: _vm.data.plantCd,
                                  name: "managementDepts",
                                },
                                model: {
                                  value: _vm.data.managementDepts,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "managementDepts", $$v)
                                  },
                                  expression: "data.managementDepts",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("c-checkbox", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  isFlag: true,
                                  label: "사용여부",
                                  name: "useFlag",
                                },
                                model: {
                                  value: _vm.data.useFlag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "useFlag", $$v)
                                  },
                                  expression: "data.useFlag",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-textarea", {
                                attrs: {
                                  counter: true,
                                  editable: _vm.editable && _vm.dataeditable,
                                  label: "단위공정 설명",
                                  name: "processDesc",
                                  rows: 2,
                                },
                                model: {
                                  value: _vm.data.processDesc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "processDesc", $$v)
                                  },
                                  expression: "data.processDesc",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("c-select", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  codeGroupCd: "WORK_CYCLE_CD",
                                  type: "edit",
                                  itemText: "codeName",
                                  itemValue: "code",
                                  name: "workCycleCd",
                                  label: "작업발생 주기",
                                },
                                model: {
                                  value: _vm.data.workCycleCd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "workCycleCd", $$v)
                                  },
                                  expression: "data.workCycleCd",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  type: "number",
                                  label: "작업시간(1회)",
                                  name: "workingTime",
                                },
                                model: {
                                  value: _vm.data.workingTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "workingTime", $$v)
                                  },
                                  expression: "data.workingTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  label: "사용온도",
                                  name: "temperature",
                                  suffix: "°C",
                                },
                                model: {
                                  value: _vm.data.temperature,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "temperature", $$v)
                                  },
                                  expression: "data.temperature",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-upload", {
                                attrs: {
                                  attachInfo: _vm.attachInfoL2Photo,
                                  editable: _vm.editable && _vm.dataeditable,
                                  label: "단위공정 사진",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }