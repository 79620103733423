<template>
  <div>
    <c-search-box @enter="getSearchList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="edit" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-tree-table
          ref="processTable"
          title="공정 목록"
          parentProperty="upProcessCd"
          customID="processCd"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
                  <c-btn label="검색" icon="search" @btnClicked="getSearchList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <!-- 공정상세 -->
          <c-card :title="title" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="신규 공정" icon="add" @btnClicked="addParent" />
                <c-btn v-if="editable && this.data.processLevelCd == '10' && selectedProcessCd" label="신규 단위공정" icon="add" @btnClicked="addChild" />
                <c-btn v-if="editable && dataeditable && selectedProcessCd" label="삭제" icon="remove" @btnClicked="removeProcess" />
                <c-btn
                  v-if="editable && dataeditable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail" v-if="data.processLevelCd != '20'">
              <div class="col-6">
                <!-- 공정명 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="공정명"
                  name="processName"
                  v-model="data.processName">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 공정관리번호 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="공정관리번호"
                  name="processNo"
                  v-model="data.processNo">
                </c-text>
              </div>
              <div class="col-3">
                      <c-plant
                  :required="true"
                  :editable="editable && dataeditable"
                  label="사업장"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-4">
                <!-- 정렬순서 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="순번"
                  name="orderNo"
                  v-model="data.orderNo">
                </c-text>
              </div>
              <div class="col-4">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="PSM대상"
                  name="psmFlag"
                  v-model="data.psmFlag"
                />
              </div>
              <div class="col-4">
                <!-- 사용여부 -->
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-5">
                <c-text
                  :editable="editable && dataeditable"
                  label="주재료"
                  name="mainMaterial"
                  v-model="data.mainMaterial">
                </c-text>
              </div>
              <div class="col-5">
                <c-text
                  :editable="editable && dataeditable"
                  label="생산품"
                  name="productName"
                  v-model="data.productName">
                </c-text>
              </div>
              <div class="col-2">
                <c-text
                  :editable="editable && dataeditable"
                  label="근로자수"
                  name="workerCnt"
                  :suffix="$language('명')"
                  type="number"
                  v-model="data.workerCnt">
                </c-text>
              </div>
              <div class="col-12">
                <c-textarea
                  :counter="true"
                  :editable="editable && dataeditable"
                  label="공정설명"
                  name="processDesc"
                  :rows="2"
                  v-model="data.processDesc">
                </c-textarea>
              </div>
              <div class="col-12">
                <c-upload
                  :attachInfo="attachInfoL1Photo"
                  :editable="editable && dataeditable"
                  label="공정 사진">
                </c-upload>
              </div>
              <div class="col-12">
                <c-upload
                  :attachInfo="attachInfoL1Hazard"
                  :editable="editable && dataeditable"
                  label="그 밖의 유해위험정보">
                </c-upload>
              </div>
            </template>
            <template slot="card-detail" v-if="data.processLevelCd == '20'">
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="단위공정명"
                  name="processName"
                  v-model="data.processName">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 공정관리번호 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="공정관리번호"
                  name="processNo"
                  v-model="data.processNo">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 정렬순서 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="순번"
                  name="orderNo"
                  v-model="data.orderNo">
                </c-text>
              </div>
              <div class="col-3">
                <!-- 상위공정 -->
                <c-text
                  :editable="false"
                  label="상위공정"
                  name="upProcessName"
                  v-model="data.upProcessName">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 관리부서 -->
                <c-dept-multi label="관리부서" :editable="editable && dataeditable" :parentCheckDepts="data.managementDepts" :plantCd="data.plantCd" name="managementDepts" v-model="data.managementDepts" />
              </div>
              <div class="col-3">
                <!-- 사용여부 -->
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-12">
                <c-textarea
                  :counter="true"
                  :editable="editable && dataeditable"
                  label="단위공정 설명"
                  name="processDesc"
                  :rows="2"
                  v-model="data.processDesc">
                </c-textarea>
              </div>
              <div class="col-3">
                <c-select
                  :editable="editable && dataeditable"
                  codeGroupCd="WORK_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="workCycleCd"
                  label="작업발생 주기"
                  v-model="data.workCycleCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable && dataeditable"
                  type="number"
                  label="작업시간(1회)"
                  name="workingTime"
                  v-model="data.workingTime">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable && dataeditable"
                  label="사용온도"
                  name="temperature"
                  suffix="°C"
                  v-model="data.temperature">
                </c-text>
              </div>
              <div class="col-12">
                <c-upload
                  :attachInfo="attachInfoL2Photo"
                  :editable="editable && dataeditable"
                  label="단위공정 사진">
                </c-upload>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'process-manage',
  data() {
    return {
      useFlagItems: [],
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            // 공정명
            label: '공정명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'orderNo',
            field: 'orderNo',
            // 순번
            label: '순번',
            align: 'center',
            style: 'width: 70px',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: '사용여부',
            align: 'center',
            style: 'width: 70px',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.process.insert.url,
      saveType: 'POST',
      selectedProcessCd: '',
      selectedProcess: null,
      attachInfoL1Photo: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UP',
        taskKey: '',
      },
      attachInfoL2Photo: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UNIT_PHOTO',
        taskKey: '',
      },
      attachInfoL1Hazard: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UNIT_HAZARD',
        taskKey: '',
      },
      psmYnItems: [],
      data: {
        plantCd: null,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: '',
        upProcessName: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    title() {
      return this.data.processLevelCd == '20' ? '단위공정 상세' : '공정상세';
    },
  },
  methods: {
    init() {
      this.psmYnItems = [
        { code: 'Y', codeName: this.$language('대상') },
        { code: 'N', codeName: this.$language('해당없음') },
      ]
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.process.list.url;
      this.detailUrl = selectConfig.mdm.process.get.url;
      this.checkUrl = selectConfig.mdm.process.check.url;
      this.insertUrl = transactionConfig.mdm.process.insert.url;
      this.updateUrl = transactionConfig.mdm.process.update.url;
      this.deleteUrl = transactionConfig.mdm.process.delete.url;
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getSearchList() {
      this.getList();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        if (this.selectedProcessCd != '') {
          this.$refs["processDetail"].rowClick({ processCd: this.selectedProcessCd });
        } else {
          if (this.$refs["processDetail"] !== undefined) {
            this.$refs["processDetail"].reset();
          }
        }
      },);
    },
    rowClick(row) {
      // 상세조회
      this.selectedProcess = row;
      this.selectedProcessCd = row.processCd;
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailUrl, this.selectedProcessCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.$set(this.attachInfoL1Photo, 'taskKey', row.processCd)
        this.$set(this.attachInfoL1Hazard, 'taskKey', row.processCd);
        this.$set(this.attachInfoL2Photo, 'taskKey', row.processCd);
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    reset() {
      this.rowRemoveSelect();
      this.selectedProcessCd = '';
      this.selectedProcess = null;
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        plantCd: null,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: '',
        upProcessName: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
      };
      this.$set(this.attachInfoL1Photo, 'taskKey', '');
      this.$set(this.attachInfoL1Hazard, 'taskKey', '');
      this.$set(this.attachInfoL2Photo, 'taskKey', '');
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.processNo);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId
                  this.data.chgUserId = this.$store.getters.user.userId
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내',
                message:
                '동일한 공정관리번호가 존재합니다.', // 동일한 공정관리번호가 존재합니다.
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeProcess() {
      let msg = '삭제하시겠습니까?';
      if (this.selectedProcess.processLevelCd == '10') {
        msg = '상위공정 삭제 시 하위공정도 모두 삭제됩니다.\r\n삭제하시겠습니까?';
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: msg, 
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.processCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getSearchList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(_result) {
      let _processCd = _result.data;
      if (this.saveType == 'POST') {
        this.$set(this.attachInfoL1Photo, 'taskKey', _processCd)
        this.$set(this.attachInfoL1Photo, 'isSubmit', uid())
        this.$set(this.attachInfoL1Hazard, 'taskKey', _processCd);
        this.$set(this.attachInfoL1Hazard, 'isSubmit', uid())
        this.$set(this.attachInfoL2Photo, 'taskKey', _processCd)
        this.$set(this.attachInfoL2Photo, 'isSubmit', uid())
      }
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getSearchList();
    },
    addParent() {
      this.attachable = true;
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        plantCd: this.searchParam.plantCd,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: '',
        upProcessName: '',
        processLevelCd: '10',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
      };
      this.$set(this.attachInfoL1Photo, 'taskKey', '')
      this.$set(this.attachInfoL1Hazard, 'taskKey', '');
    },
    addChild() {
      this.attachable = true;
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        plantCd: this.selectedProcess.plantCd,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: this.selectedProcess.processCd,
        upProcessName: this.selectedProcess.processName,
        processLevelCd: '20',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
      };
      this.$set(this.attachInfoL2Photo, 'taskKey', '');
    },
  }
};
</script>
